/* Vendor files ------------------------------------ */

import aos from './partial/aos';
import change from './partial/header-change-on-scroll';
import hamburgerMenu from './partial/hamburger-menu';

// Accordion
$('.accordion-trigger').click(function () {
	$(this).toggleClass('active');
	$(this).next().slideToggle(200).parent().toggleClass('is-active');
});

$('h1, h2, h3, h4, h5, p').each(function () {
	var tekst = $(this).html();
	tekst = tekst.replace(/(\s)([\S])[\s]+/g, '$1$2&nbsp;'); //jednoznakowe
	tekst = tekst.replace(/(\s)([^<][\S]{1})[\s]+/g, '$1$2&nbsp;'); //dwuznakowe
	$(this).html(tekst);
});

var max = 200;
var tot, str;
$('.js-text-ellipsis').each(function () {
	str = String($(this).html());
	tot = str.length;
	str = tot <= max ? str : str.substring(0, max + 1) + '...';
	$(this).html(str);
});

$('.js-text-ellipsis-140').each(function () {
	var max = 140;
	var tot, str;
	str = String($(this).html());
	tot = str.length;
	str = tot <= max ? str : str.substring(0, max + 1) + '...';
	$(this).html(str);
});

$(document).ready(function () {
	// document.querySelectorAll('.booksy-widget-button')[0].setAttribute('role', 'button');
	// document.querySelectorAll('.booksy-widget-button')[1].setAttribute('role', 'button');
	// document.querySelectorAll('.booksy-widget-button')[2].setAttribute('role', 'button');

	jQuery('.js-first-pasaz').click(function () {
		jQuery('.js-pasaz .booksy-widget-button').trigger('click');
		return false;
	});

	jQuery('.js-first-europlex').click(function () {
		jQuery('.js-europlex .booksy-widget-button').trigger('click');
		return false;
	});

	jQuery('.js-first-piaseczno').click(function () {
		jQuery('.js-piaseczno .booksy-widget-button').trigger('click');
		return false;
	});
});
